@tailwind base;
@tailwind components;
@tailwind utilities;

#analyzer:checked ~ .dot {
  transform: translateX(100%);
  background-color: #a21caf;
}

#analyzer:checked ~ .rail {
  background-color: rgb(122, 110, 110);
}
